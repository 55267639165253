(function( $ ) {

    $.fn.openableContainer = function() {

        var jqAllAffectedContainers = this;

        jqAllAffectedContainers.each(function() {
            var jqContainer = $(this);
            var opener = jqContainer.find('.heading>*');
            opener.click(function(e) {
                e.preventDefault();
                if (jqContainer.hasClass('open')) {
                    jqContainer.removeClass('open');
                } else {
                    jqContainer.addClass('open');
                }
            })

        });

        return this;
    };

}( jQuery ));

document.addEventListener('DOMContentLoaded', function() {
    $('.contacts-info-block.openable').openableContainer();
});
