import commonStyles from '../sass/common.scss';
import partnersSliderJS from './includes/partners_slider.js';
import openableJS from './includes/openable.js';
import news1IMG from '../img/news-1.png';
import news2IMG from '../img/news-2.png';
import news3IMG from '../img/news-3.png';
import news4IMG from '../img/news-4.png';
import news5IMG from '../img/news-5.png';
import news6IMG from '../img/news-6.png';
import news7IMG from '../img/news-7.png';
import news8IMG from '../img/news-8.png';
import news9IMG from '../img/news-9.png';
